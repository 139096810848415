import React, { useEffect, useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/InfoForCustomerTable';
import Axios from 'axios';

const InfoForCustomer = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/terms`, [data]);
  }, [fetchData]);

  let columnKeys = ['label'];

  let tableData = [];

  if (data && data?.data?.items?.length !== null) {
    tableData = data?.items?.map((item) => {
      return {
        ...item,
      };
    });
  }

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/term/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Naslovna strana obrisana.',
        placement: 'bottomRight',
      });
      // history.push('/admin/Pages');
      window.location.reload();
    } catch (error) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte ponovo kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table data-table special-data-table'>
      <div style={{ paddingBottom: '20px', paddingTop: '45px', paddingLeft: '5px' }}>
        {/* <Link to='/admin/new-info-text'>
          <Button type='primary'>Kreiraj novu stranicu</Button>
        </Link> */}
      </div>
      <div style={{ textAlign: 'center' }}>
        {/* {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />} */}
        {/* {!data.isLoading && data.items && data.items.length > 0 && ( */}
        <Table
          data={data.data.items}
          deleteHandler={deleteHandler}
          columnKeys={columnKeys}
          title='Pages'
          editPath='/admin/new-info-text/'
        />
        {/* )} */}
        {!data.isLoading && data.data && data.data.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoForCustomer;

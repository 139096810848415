import React, { useContext, useState, useEffect } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const InfoForCustomerTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${Array.isArray(dataIndex) ? dataIndex[0] : dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button type='secondary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getTitle = (key) => {
    switch (key) {
      case 'label':
        return 'Naziv stranice';
      case 'text':
        return 'Text';
      default:
        console.warn('default');
    }
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: getTitle(item),
    align: 'center',
    dataIndex: item === 'createdBy' ? 'user' : ['title', 'category'].includes(item) ? item === 'title' : item,
    ...getColumnSearchProps(
      item === 'createdBy' ? 'user' : ['title', 'category'].includes(item) ? item === 'title' : item,
    ),
  }));

  // columns.push({
  //   title: 'Izmeni akcije',
  //   width: '6%',
  //   align: 'center',
  //   render: (text, record) => (
  //     <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
  //       <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //         <button onClick={() => handleToggleModal(record._id)} type='button'>
  //           <EditOutlined
  //             className='icon-unlock'
  //             title={`Edit ${title.toLowerCase()}`}
  //             style={{ textDecoration: 'none', color: 'black' }}
  //           />
  //           <EditFilled
  //             className='icon-lock'
  //             title={`Edit ${title.toLowerCase()}`}
  //             style={{ textDecoration: 'none', color: 'black' }}
  //           />
  //         </button>
  //       </div>
  //     </div>
  //   ),
  // });

  columns.push({
    title: 'Izmeni tekst',
    width: '6%',
    align: 'center',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={`/admin/new-info-text/${record._id}`} type='button'>
            <FormOutlined
              className='icon-unlock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <FormOutlined
              className='icon-lock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>
        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={'Ovo će obrisati podatke o sekciji. Da li želite da obrišete sekciju?'}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Poništi'
          >
            <DeleteOutlined title={'Brisanje sekcije'} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className='table-data-okvir'>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
        className='main-product-table'
      />

      {/* <ActionProductsModal modalId={modalId} showModal={showModal} setShowModal={setShowModal} /> */}
    </div>
  );
};

export default InfoForCustomerTable;

import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, Radio, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  street: undefined,
  houseNo: undefined,
  appNo: undefined,
  city: undefined,
  zip: undefined,
  legalType: 'PRIVATE',
  taxId: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName, setPromptName] = useState();
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      console.log(values);
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    setTimeout(() => {
      // window.location.reload();
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        if (id === currentuser.data.id) {
          sessionStorage.removeItem('user');
          currentuser.setLoggedIn(false);
          history.push('/login');
        }
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas pokušjte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <div className='dashboard'>
        {profile && (
          <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
            Obriši korisnika <DeleteOutlined />
          </div>
        )}
        <div className='panel panel-body'>
          <Form
            {...layout}
            name='basic'
            initialValues={user}
            onFinish={(values) => onFinish(values, isNew)}
            onFinishFailed={onFinishFailed}
            layout='horizontal'
            form={form}
          >
            <Form.Item name='legalType'>
              <Radio.Group onChange={handleLegalType}>
                <Radio value='Private'>Fizičko lice</Radio>
                <Radio value='Company'>Pravno lice</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Unesite Email adresu!',
                },
              ]}
            >
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Unesite ime!',
                },
              ]}
            >
              <Input placeholder='Ime' />
            </Form.Item>

            <Form.Item
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Unestite prezime!',
                },
              ]}
            >
              <Input placeholder='Prezime' />
            </Form.Item>

            <Form.Item
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Unestite broj telefona!',
                },
              ]}
            >
              <Input placeholder='Telefon' />
            </Form.Item>

            <Form.Item
              name='street'
              rules={[
                {
                  required: true,
                  message: 'Unestite naziv ulice!',
                },
              ]}
            >
              <Input placeholder='Ulica' />
            </Form.Item>

            <Form.Item
              name='houseNo'
              rules={[
                {
                  required: true,
                  message: 'Unestite broj kuće/zgrade!',
                },
              ]}
            >
              <Input placeholder='Kućni broj' />
            </Form.Item>

            <Form.Item name='appNo'>
              <Input placeholder='Broj stana (ako je zgrada)' />
            </Form.Item>

            <Form.Item
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Unestite naziv grada!',
                },
              ]}
            >
              <Input placeholder='Grad' />
            </Form.Item>

            <Form.Item
              name='zip'
              rules={[
                {
                  required: true,
                  message: 'Unestite poštanski broj !',
                },
              ]}
            >
              <Input placeholder='Poštanski broj' />
            </Form.Item>

            {legalType === 'COMPANY' && (
              <>
                <Form.Item
                  name='companyName'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite naziv kompanije!',
                    },
                  ]}
                >
                  <Input placeholder='Naziv kompanije' />
                </Form.Item>

                <Form.Item
                  name='taxId'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite PIB broj!',
                    },
                  ]}
                >
                  <Input placeholder='PIB' />
                </Form.Item>
              </>
            )}

            {isNew && (
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Unesite lozinku!',
                  },
                ]}
              >
                <Input.Password placeholder='Lozinka' />
              </Form.Item>
            )}

            <Form.Item
              name='role'
              rules={[
                {
                  required: true,
                  message: 'Odaberite nivo pristupa!',
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownAlign={{ offset: [0, -110] }}
                mode='multiple'
                placeholder='Nivo pristupa'
              >
                <Select.Option value='admin'>Admin</Select.Option>
                <Select.Option value='user'>Korisnik</Select.Option>
              </Select>
            </Form.Item>

            {!isNew && (
              <Form.Item
                name='status'
                rules={[
                  {
                    required: true,
                    message: 'Odaberite status naloga!',
                  },
                ]}
              >
                <Select getPopupContainer={(trigger) => trigger.parentNode}>
                  <Select.Option value='AKTIVAN'>AKTIVAN</Select.Option>
                  <Select.Option value='SUSPENDOVAN'>SUSPENDOVAN</Select.Option>
                  <Select.Option value='OBRISAN'>OBRISAN</Select.Option>
                  <Select.Option value='ČEKA_NA_ODOBRENJE'>ČEKA NA ODOBRENJE</Select.Option>
                </Select>
              </Form.Item>
            )}

            <Form.Item {...tailLayout}>
              <div>
                <Button type='primary' htmlType='submit' style={{ float: 'right', marginLeft: '15px' }}>
                  {data ? 'Snimi' : 'Dodaj korisnika'}
                </Button>
                <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                  Odustani
                </Button>
              </div>
            </Form.Item>
          </Form>
          {result && (
            <Result
              title='Korisnik je kreiran i verifikacioni email je poslat!'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    window.location.reload();
                  }}
                >
                  Potvrdi
                </Button>
              }
            />
          )}

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Yes'
            cancelText='No'
          >
            <p>Are you sure you want to delete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;

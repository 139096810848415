import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { notification, Button, Tag } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'featuredProduct',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'superCena',
  'IDOsnovneGrupe',
  'IDgrupe',
  'artikalID',
  'createdAt',
  'kreirano',
  'nacinzaokrtranspak',
  'nazivosnovnegrupe',
  'nekoristise',
  'opis',
  'pdvstopa',
  'seoUrl',
  'sifraosnovnegrupe',
  'specialCategory',
  'specialPrice',
  'status',
  'updatedAt',
  'zaokrtranspak',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/data?string=dashboard`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Podatak je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys = [
    'sifra',
    'naziv',
    'nazivgrupe',
    'Cena',
    'Popust',
    'CenaPopust',
    'naakciji',
    'status',
    'slikaStatus',
  ];

  // PRICE FORMATER //

  const formatPrice = (number) => {
    if (number !== NaN) {
      const price = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      }).format(number);
      return price.replace('€', '');
    } else {
      return 0;
    }
  };

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length !== null) {
    tableData = data.data.items
      // .filter((item) => item.Cena !== 0)
      .map((item) => {
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();

        let startDate = item.startDate;
        let endDate = item.endDate;

        if (startDate && endDate) {
          startDate = new Date(item.startDate).toLocaleDateString('sr-RS');
          endDate = new Date(item.endDate).toLocaleDateString('sr-RS');
        }

        if (item.naakciji === 'D') {
          item.naakciji = 'DA';
        }
        if (item.naakciji === 'N') {
          item.naakciji = 'NE';
        }

        if (!item.featureImage) {
          item.slikaStatus = <Tag color='red'>Nema sliku</Tag>;
        } else {
          item.slikaStatus = <Tag color='green'>Ima sliku</Tag>;
        }

        return {
          ...item,
          Cena: formatPrice(item.Cena),
          CenaPopust: formatPrice(item.CenaPopust),
          razlikaUCeni: formatPrice(item.razlikaUCeni),
          startDate: startDate,
          endDate: endDate,
        };
      });
  }

  return (
    <div className='table data-table special-data-table'>
      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Podaci'
            editPath='/admin/edit-data/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Data;

import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import { Form, Checkbox, DatePicker, Space, Input, Button, Select, Result, Radio, notification, Modal } from 'antd';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const DataForm = (props) => {
  const { data, showModal, setShowModal } = props;
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [isChecked, setIsChecked] = useState(false);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [date, setDate] = useState([]);

  useEffect(() => {
    const onActions = data.naakciji === 'N' ? false : true;

    setIsChecked(onActions);
  }, [data]);

  const dateChange = (date, dateString) => {
    if (date) {
      setDate(date);
    } else {
      setDate(dateString);
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      form.setFieldsValue({
        naakciji: data.naakciji,
      });
    }

    if (data.startDate) {
      setDate([moment(data.startDate), moment(data.endDate)]);
    } else {
      setDate([]);
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const { checked } = e.target;
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const onSubmitHandler = async (form) => {
    const payload = {
      naakciji: isChecked ? 'D' : 'N',
      startDate: date[0],
      endDate: date[1],
    };

    try {
      const dataId = data._id;

      let response = await Axios.put(
        `${SERVER_URL}/special-section/${dataId}`,
        { ...payload },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );

      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      notification.success({
        message: 'Podaci su izmenjeni.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='panel panel-body product-action'>
        <Form
          {...layout}
          name='basic'
          onChange={onChangeHandler}
          onFinish={(values) => onSubmitHandler(values)}
          onFinishFailed={() => console.log('failed')}
          layout='horizontal'
          form={form}
        >
          {/* <Form.Item name='naakciji' label='Proizvod je na akciji' style={{ fontWeight: 'bold' }}>
            <Checkbox checked={isChecked}></Checkbox>
          </Form.Item> */}

          <Form.Item name='sponuda' label='Proizvod je u Specijalnoj ponudi' style={{ fontWeight: 'bold' }}></Form.Item>
          <Space direction='vertical' size={12}>
            <RangePicker value={date ? date : ''} format={'DD-MM-YYYY'} showTime onChange={dateChange} />
          </Space>

          <Form.Item {...tailLayout}>
            <div style={{ marginTop: '25px' }}>
              <Button type='primary' htmlType='submit' style={{ float: 'right', marginLeft: '15px' }}>
                {data ? 'Snimi' : 'Dodaj akciju'}
              </Button>
              <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                Odustani
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DataForm;

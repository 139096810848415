import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import { Select, Button, Form, Row, Col, Input, Checkbox } from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import GalleryModal from './GalleryModal';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 30;

const formInit = {
  _id: null,
  title: '',
  gallery: [],
  bannerType: '',
  status: 'ACTIVE',
};

const options = [
  {
    title: 'Hero slider',
    bannerType: 0,
  },
  {
    title: 'Dva banera',
    bannerType: 1,
  },
  {
    title: 'Jedan baner',
    bannerType: 2,
  },
];

const BannerForm = ({ isNew, banners, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });

  if (banners) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete banners[key]);

  let initialValues = { ...formInit, ...banners };

  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(
    initialValues?.gallery?.length > 0 ? initialValues?.gallery : [],
  );
  const [whichBanerSelected, setWhichBanerSelected] = useState(banners?.title);

  const onFinish = async (values, isNew) => {
    console.log('values', values);
    if (!isNew) {
      values._id = banners._id;
    }

    const selectedOption = options.find((option) => option.label === values.label);
    values.bannerType = selectedOption ? selectedOption.bannerType : '';
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  console.log('banners', banners);

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !banners)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item label='Naziv' labelCol={{ span: 6 }} name='title'>
                  <Select onChange={(value) => setWhichBanerSelected(value)}>
                    {options.map((item, index) => (
                      <Option key={index} value={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Mobilna verzija' name='mobileVersion' valuePropName='checked'>
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  label='URL za prvu sliku'
                  name={'urlImage1'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za drugu sliku'
                  name={'urlImage2'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za trecu sliku'
                  name={'urlImage3'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za cetvrtu sliku'
                  name={'urlImage4'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za petu sliku'
                  name={'urlImage5'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za sestu sliku'
                  name={'urlImage6'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>
                <Form.Item
                  label='URL za sedmu sliku'
                  name={'urlImage7'}
                  rules={[
                    {
                      required: false,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input prefix='https://www.termoklik.rs/' suffix='.rs' />
                </Form.Item>

                <Form.Item
                  label='Status'
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Please select status!',
                    },
                  ]}
                >
                  <Select>
                    {['ACTIVE', 'SUSPENDED'].map((option, index) => (
                      <Select.Option key={`${option}_${index}`} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              {whichBanerSelected === 'Hero slider' && (
                <div className='panel panel-primary' style={{ marginTop: '15px' }}>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>Slike za hero slider</h4>
                  </div>

                  <Form.Item name='gallery' valuePropName='image'>
                    <div className='panel-body'>
                      {currentGallery?.length > 0 && (
                        <DNDGallery
                          deleteGalleryImageHandler={deleteGalleryImageHandler}
                          editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                          form={form}
                          setGallery={setCurrentGallery}
                        />
                      )}

                      {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                    </div>
                  </Form.Item>
                </div>
              )}

              {whichBanerSelected === 'Dva banera' && (
                <div className='' style={{ marginTop: '15px' }}>
                  <div className='panel panel-primary'>
                    <div className='panel-heading'>
                      <h4 className='panel-title'>Slike za dva banera</h4>
                    </div>

                    <Form.Item name='gallery' valuePropName='image'>
                      <div className='panel-body'>
                        {currentGallery?.length > 0 && (
                          <DNDGallery
                            deleteGalleryImageHandler={deleteGalleryImageHandler}
                            editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                            form={form}
                            setGallery={setCurrentGallery}
                          />
                        )}

                        {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                      </div>
                    </Form.Item>
                  </div>
                </div>
              )}

              {whichBanerSelected === 'Jedan baner' && (
                <div className='panel panel-primary' style={{ marginTop: '15px' }}>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>Slika za jedan baner</h4>
                  </div>

                  <Form.Item name='featureImage' valuePropName='image'>
                    <div className='panel-body'>
                      <UploadBox
                        editHandler={editImageTrigger}
                        deleteHandler={deleteFeatureImageHandler}
                        image={image}
                        index={0}
                        name='featureImage'
                      />
                    </div>
                  </Form.Item>
                </div>
              )}
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Ažuriraj'} baner
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/banners/'
            imageType='data'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default BannerForm;

import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import BannerForm from '../../components/forms/BannerForm';
const EditBanner = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [banners, fetchBanners] = useAxios('', {}, currentuser.data.token, 'get');
  const { bannerId } = props.match.params;

  useEffect(() => {
    if (bannerId) fetchBanners(`${SERVER_URL}/banners/${bannerId}`, {});
  }, [fetchBanners, bannerId]);

  const onSubmit = async (formBanner, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/banners` : `${SERVER_URL}/banners/${bannerId}`;
    try {
      await Axios[method](route, formBanner, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Banner ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/banners');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isBannersFetched = !banners.isLoading && banners.data;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/banners'>
          <Button type='primary'>Svi baneri</Button>
        </Link>
      </div>

      <div>
        {/* {!isBannersFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )} */}

        {!bannerId && (
          <BannerForm isNew={true} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}

        {!banners.isError && bannerId && banners.data && isBannersFetched && (
          <BannerForm
            isNew={false}
            banners={banners?.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditBanner;

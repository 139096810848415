import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button, Avatar, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import EditUserModal from '../modals/EditModal';
import { SERVER_URL } from '../../config';

const BadgeTable = ({ data, deleteHandler, columnKeys, title, editHandler }) => {
  const user = useContext(UserContext);
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }} type='secondary'>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getTitle = (key) => {
    switch (key) {
      case 'name':
        return 'Naziv';
      case 'position':
        return 'Pozicija';
      case 'color':
        return 'Boja';

      default:
        console.warn('default');
    }
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: getTitle(item),
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns.push({
    title: 'Akcija',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={`/admin/edit-badge/${record._id}`} type='button'>
            <EditOutlined
              className='icon-unlock'
              title={`Edit ${record.name.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => editHandler(record._id)}
            />

            <EditFilled
              className='icon-lock'
              title={`Edit ${record.name.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => editHandler(record._id)}
            />
          </Link>
        </div>

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`This will delete ${record.name.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='OK'
            cancelText='Odustani'
            cancelButtonProps={{ type: 'secondary' }}
            okButtonProps={{ type: 'primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Delete ${record.name.toLowerCase()}`} />
            <DeleteFilled className='icon-lock' title={`Delete ${record.name.toLowerCase()}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className='table-user-okvir'>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }}
      />
      <EditUserModal userId={userID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default BadgeTable;

import React, { useState, useEffect, useContext } from 'react';
import { Divider, Input, Select, Button, Form, Checkbox, Radio } from 'antd';
import { SketchPicker } from 'react-color';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const { Option } = Select;

const formInit = {
  name: '',
  position: '',
  products: [],
  color: '#f17013',
};

const BadgeForm = ({ setEdit, data, onSubmit }) => {
  const [form] = Form.useForm();

  // [('createdAt', 'updatedAt', '__v', 'values')].forEach((key) => delete editAttr[key]);
  const currentuser = useContext(UserContext);

  const [value, setValue] = useState(1);
  const [sketchPickerColor, setSketchPickerColor] = useState(data.color ? data.color : '#f17013');
  const [products, fetchProducts] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    fetchProducts(`${SERVER_URL}/data`, [products]);
  }, [fetchProducts]);

  let initialValues = { ...formInit, ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-primary' style={{ width: '60%', paddingLeft: '30px' }}>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span style={{ fontWeight: '600' }}>{!data.name ? 'DODAJ BEDŽ' : 'IZMENI BEDŽ'}</span>
          {!data.name && (
            <button className='btn-close' onClick={() => setEdit({ visible: false, badge: {} })}>
              <span className='icon'>&#10005;</span>
            </button>
          )}
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data.name)}
          layout='vertical'
          form={form}
        >
          <Form.Item label='Naziv bedža' name='name' rules={[{ required: true, message: 'Upišite naziv bedža!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label='Pozicija bedža'
            name='position'
            rules={[{ required: true, message: 'Odaberite poziciju bedža!' }]}
          >
            <Radio.Group onChange={onChange} value={value}>
              {/* <Radio value={0}>gore levo</Radio> */}
              <Radio value={1}>gore desno prvi</Radio>
              <Radio value={2}>gore desno drugi</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label='Proizvodi kojima će bedž biti dodat' name={['products']}>
            <Select
              showSearch
              optionFilterProp='children'
              mode='multiple'
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {products &&
                products?.data &&
                products?.data?.items?.length > 0 &&
                products?.data?.items?.map((item) => {
                  return (
                    <Select.Option value={item._id} key={item._id}>
                      {item.naziv}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item label='Boja bedža za akciju' name='color'>
            <div className='sketchpicker'>
              <div
                style={{
                  backgroundColor: `${sketchPickerColor}`,
                  width: 100,
                  height: 50,
                  border: '2px solid white',
                }}
              ></div>
              <SketchPicker
                onChange={(color) => {
                  setSketchPickerColor(color.hex);
                  form.setFieldsValue({ color: color.hex });
                }}
                color={sketchPickerColor}
              />
            </div>
          </Form.Item>

          <Divider type='horizontal' />

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Snimi
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BadgeForm;

import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import dayjs from 'dayjs';
import EditUserModal from '../../components/modals/EditModal';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchUser, fetchLog]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  useEffect(() => {
    if (id) {
      let sevenDayBefor = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = dayjs(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const updateUser = async (data) => {
    if (data) {
      let fromTo = data.from + '||' + data.to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  };

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>Svi korisnici</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Nešto je pogrešno!</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            {/* <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} /> */}

            {/*<Link to={'/admin/edit-user/' + id}>*/}

            {/*</Link>*/}
            {/* </div>
              <Content style={{ padding: '15px', minHeight: 280, color: '#000' }}>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Email:</span> {user.data ? user.data.email : 'Email'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  <span className='bold-label'>Nivo pristupa:</span> {user.data ? user.data.role[0] : 'Nivo pristupa'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Status:</span> {user.data ? user.data.status : 'Status'}
                </p>
                <div style={{ paddingBottom: '1rem' }}>
                  <Button
                    type='primary'
                    block
                    icon={<EditOutlined style={{ marginRight: '5px' }} />}
                    style={{ marginTop: '1rem' }}
                    onClick={() => setShowModal(!showModal)}
                  >
                    Izmena korisnika
                  </Button>
                </div> */}
            {/* {currentuser.data.id === id && (
                  <Button className='log-out-btn' type='secondary' onClick={handleSignOutSubmit}>
                    Log out
                  </Button>
                )} */}
            {/* </Content> */}
            {/* </Sider> */}
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFIL' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Podaci korisnika' bordered={false}>
                      <p>
                        <Text strong>Ime: </Text>
                        {user.data ? user.data.firstName : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Prezime: </Text>
                        {user.data ? user.data.lastName : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user.data ? user.data.email : 'Nema podataka'}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <Text strong>Nivo pristupa: </Text>
                        {user.data ? user.data.role[0] : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user.data ? user.data.status : 'Nema podataka'}
                      </p>
                    </Card>
                  </div>
                  <div style={{ paddingBottom: '1rem', width: '250px' }}>
                    <Button
                      type='primary'
                      block
                      icon={<EditOutlined style={{ marginRight: '5px' }} />}
                      style={{ marginTop: '1rem' }}
                      onClick={() => setShowModal(!showModal)}
                    >
                      Izmena korisnika
                    </Button>
                  </div>
                  <div style={{ width: '250px' }}>
                    {currentuser.data.id === id && (
                      <Button className='log-out-btn' type='secondary' onClick={handleSignOutSubmit}>
                        Odjavi se
                      </Button>
                    )}
                  </div>
                </TabPane>

                <TabPane tab='ZAPISI' key='2'>
                  {log.data.length > 0 ? (
                    <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} />
                  ) : (
                    'Nema podataka'
                  )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewUser;

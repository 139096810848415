import React, { useContext, useEffect, useState } from 'react';

import JoditEditor from 'jodit-react';
import { Divider, Input, Select, Button, Form, Row, Col } from 'antd';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import GalleryModal from './GalleryModal';

const { Option } = Select;

const configJodit = {
  style: {
    fontFamily: 'Poppins',
  },
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
    'image',
  ],
};

const formInit = {
  _id: null,
  blogLabel: undefined,
  pageUrl: undefined,
  blogText: undefined,
};

const BlogForm = ({ isNew, data, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  let initialValues = { ...formInit, ...data };

  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(
    initialValues?.gallery?.length > 0 ? initialValues?.gallery : [],
  );

  // Select ATTRIBUTES
  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  // className='employeeField'
                  label='Naslov bloga'
                  labelCol={{ span: 6 }}
                  name='blogLabel'
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas izaberite brend!',
                    },
                  ]}
                >
                  <Input style={{ maxWidth: '600px', paddingLeft: '10px' }} />
                </Form.Item>

                <Form.Item label='Google naziv' name='seoNaziv'>
                  <Input style={{ maxWidth: '600px', paddingLeft: '10px' }} />
                </Form.Item>

                <Form.Item label='Google opis' name='googleDescription'>
                  <Input style={{ maxWidth: '600px', paddingLeft: '10px' }} />
                </Form.Item>

                <Form.Item label='Google ključne reči' name='keywords'>
                  <Input style={{ maxWidth: '600px', paddingLeft: '10px' }} />
                </Form.Item>

                <Form.Item
                  label='URL'
                  name={'pageUrl'}
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input
                    prefix='https://www.termoklik.rs/novosti/'
                    suffix='.rs'
                    style={{ maxWidth: '600px', paddingLeft: '10px' }}
                  />
                </Form.Item>

                <Form.Item label='Kratak tekst za blog' name={'blogShortText'}>
                  <JoditEditor name={'blogShortText'} config={configJodit} />
                </Form.Item>

                <Form.Item label='Sadržaj bloga' name={'blogText'}>
                  <JoditEditor
                    name={'blogText'}
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='panel panel-primary' style={{ marginTop: '15px', marginLeft: '15px', width: '400px' }}>
            <div className='panel-heading'>
              <h4 className='panel-title'>Slika za blog karticu</h4>
            </div>

            <Form.Item name='featureImage' valuePropName='image'>
              <div className='panel-body'>
                <UploadBox
                  editHandler={editImageTrigger}
                  deleteHandler={deleteFeatureImageHandler}
                  image={image}
                  index={0}
                  name='featureImage'
                />
              </div>
            </Form.Item>
          </div>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/blogs/'
              imageType='data'
              imageHeight={500}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
              onInsert={(values) => onInsert(values, modal.formKey)}
              imageId={modal.id}
              imageIndex={modal.index}
            />
          )}

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} blog
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BlogForm;

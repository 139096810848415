import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import BlogForm from '../../components/forms/BlogForm';

const EditBlog = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/blog/${id}`, {});
  }, [fetchData, id]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/blogs` : `${SERVER_URL}/blog/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Page ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/blogs');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/blogs'>
          <Button type='primary'>Svi blogovi</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!id && isDataFetched && (
          <BlogForm isNew={true} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}

        {id && !data.isError && data.data && isDataFetched && (
          <BlogForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditBlog;

import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import BadgeForm from '../../components/forms/BadgeForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';

const EditBadge = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const { id } = props.match.params;

  const [badges, fetchBadges] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    if (id) fetchBadges(`${SERVER_URL}/badge/${id}`, {});
  }, [fetchBadges, id]);

  const onSubmitBadge = async (data, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/badges` : `${SERVER_URL}/badge/${id}`;
    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Badge ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-badges');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  //   const isDataFetched = !page.isLoading && page.data && currentuser.language;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data-badges'>
          <Button type='primary'>Svi bedževi</Button>
        </Link>
      </div>
      <div>
        {!id && <BadgeForm isNew={true} onSubmit={onSubmitBadge} />}
        {id && !badges.isError && !badges.isLoading && badges.data && (
          <BadgeForm isNew={false} onSubmit={onSubmitBadge} data={badges.data} />
        )}
      </div>
    </div>
  );
};

export default EditBadge;

import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { /* Link, */ useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification /* , Button */ } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';
import { ImportExcel } from '../../components/csv/ImportExcel';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'googleDescription', 'keywords', 'content', 'image', 'user', 'url', 'tag'];

const ImportData = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/importdata`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/importdata/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Podatak je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/ImportData');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      // item.user = item.createdBy.email;
      return item;
    });
  }

  const uploadData = (val) => {
    try {
      if (val && val.file && val.file.status) {
        if (val.file.status === 'uploading') {
          notification.success({
            message: 'Datoteka je uspešno umetnuta',
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: 'Datoteka nije uspešno umetnuta',
            placement: 'bottomRight',
          });
        }
        history.push('/admin');
        history.push('/admin/ImportData');
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa ubacivanjem podataka.',
        placement: 'bottomRight',
      });
    }
  };

  const updateCategories = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/navigation`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirane kategorije!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProducts = async () => {
    try {
      const res = await Axios.post(`${SERVER_URL}/update-products`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirani proizvodi!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBrands = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/populate-brands`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirani brendovi!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCategoryNames = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/full-category-name-set`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirani nazivi kategorija!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProductPrices = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/upload-prices`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirane cene proizvoda!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateDiscountProducts = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/calculus-actions`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (res) {
        notification.success({
          message: 'Uspešno ažurirani proizvodi na popustu!',
          placement: 'bottomRight',
          duration: 4,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='table table-import-data'>
      <div className='actions-block'>
        <ImportExcel token={currentuser.data.token} uploadData={uploadData} />
      </div>

      <label style={{ marginLeft: '35px', borderBottom: '1px solid black' }}>POVLAČENJE IZ KALKULUSA</label>

      <div>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateCategories();
          }}
        >
          Ažuriraj kategorije
        </Button>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateProducts();
          }}
        >
          Ažuriraj proizvode
        </Button>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateBrands();
          }}
        >
          Ažuriraj brendove
        </Button>
      </div>

      <div>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateCategoryNames();
          }}
        >
          Ažuriraj nazive kategorija
        </Button>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateProductPrices();
          }}
        >
          Ažuriraj cene proizvoda
        </Button>
        <Button
          type='primary'
          style={{ margin: '15px' }}
          onClick={() => {
            updateDiscountProducts();
          }}
        >
          Ažuriraj proizvode na popustu
        </Button>
      </div>

      {/* <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Podaci'
            editPath='/admin/ImportData/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ImportData;

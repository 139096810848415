import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Select, Button, Form, Checkbox } from 'antd';

// 'CHOICE', 'MULTICHOICE', 'INPUT', 'NUMBER', 'CHECKBOX', 'DATE'
const ATTR_TYPES = [
  { value: 'CHOICE', label: 'ODABIR' },
  { value: 'MULTICHOICE', label: 'VIŠESTRUKI ODABIR' },
  { value: 'INPUT', label: 'UNOS TEKSTA' },
  { value: 'NUMBER', label: 'UNOS BROJA' },
  { value: 'CHECKBOX', label: 'CHECKBOX' },
  { value: 'DATE', label: 'UNOS DATUMA' },
];

const formInit = {
  name: '',
  type: '',
  unit: '',
  isRequired: false,
  isGlobal: false,
  includeInSearch: false,
};

const AttributeForm = ({ setEdit, attribute, onSubmit }) => {
  const [form] = Form.useForm();
  const editAttr = { ...attribute };
  [('createdAt', 'updatedAt', '__v', 'values')].forEach((key) => delete editAttr[key]);
  let initialValues = { ...formInit, ...editAttr };

  useEffect(() => {
    form.resetFields();
  }, [attribute, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>DODAJ / IZMENI ATRIBUT</span>

          <button className='btn-close' onClick={() => setEdit({ visible: false, attribute: {} })}>
            <span className='icon'>&#10005;</span>
          </button>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, !attribute.name)}
          layout='vertical'
          form={form}
        >
          <h3 style={{ marginBottom: '30px' }}>Dodaj atribut</h3>

          <Form.Item
            label='Naziv atributa'
            name='name'
            rules={[{ required: true, message: 'Upišite naziv atributa!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label='Tip atributa' name='type' rules={[{ required: true, message: 'Odaberite tip atributa!' }]}>
            <Select>
              {ATTR_TYPES.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label='Jedinica vrednosti' name='unit'>
            <Input />
          </Form.Item>

          <Divider type='horizontal' />

          <Form.Item label='Obavezan atribut' name='isRequired' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Globalni atribut' name='isGlobal' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Uključen u pretragu' name='includeInSearch' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Snimi
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AttributeForm;

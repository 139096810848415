import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Gallery from '../pages/gallery/Gallery';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import Newsletter from '../pages/newsletter/Newsletter';
import ImportData from '../pages/importData/ImportData';
import Pages from '../pages/page/Page';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import EditFrontPage from '../pages/page/EditFrontPage';
import EditLandingPage from '../pages/page/EditLandingPage';
import LandingPage from '../pages/page/LandingPage';
import Badges from '../pages/data/Badges';
import EditBadge from '../pages/data/EditBadge';
import InfoForCustomer from '../pages/page/InfoForCustomer';
import EditInfoForCustomer from '../pages/page/EditInfoForCustomer';
import Banner from '../pages/banners/Banner';
import EditBanner from '../pages/banners/EditBanner';
import BrandsLogo from '../pages/brands-logos/BrandsLogo';
import NewLogo from '../pages/brands-logos/NewLogo';
import Blog from '../pages/blogs/Blog';
import EditBlog from '../pages/blogs/EditBlog';

const routes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj korisnika',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Profil korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  //View user stoji ovde samo kao ruta koja omogucava da se klikom na ikonicu profil redirectuje na profil
  //Ne dirati
  // {
  //   label: 'Slike',
  //   allowed: ['admin'],
  //   children: [],
  // },
  {
    label: 'Podaci',
    children: [
      {
        label: 'Svi proizvodi',
        path: '/admin/data',
        component: Data,
        allowed: ['admin'],
        showInMenu: true,
      },
      // {
      //   label: 'Kreiraj proizvod',
      //   path: '/admin/new-data',
      //   component: EditData,
      //   allowed: ['admin'],
      //   showInMenu: true,
      // },
      {
        label: 'Izmeni proizvod',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj/Izmeni kategoriju',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Galerija slika',
        path: '/admin/gallery',
        component: Gallery,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj nove slike',
        path: '/admin/gallery/new-content',
        component: GalleryNewContent,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Bedževi',
        path: '/admin/data-badges',
        component: Badges,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New Badge',
        path: '/admin/new-badge/',
        component: EditBadge,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Badge',
        path: '/admin/edit-badge/:id',
        component: EditBadge,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Post',
  //   children: [
  //     {
  //       label: 'All posts',
  //       path: '/admin/posts',
  //       component: Post,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New post',
  //       path: '/admin/new-post',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit post',
  //       path: '/admin/edit-post/:postId',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All DraftPosts',
  //       path: '/admin/draft-post/:postId',
  //       component: DraftPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All tags',
  //       path: '/admin/tags',
  //       component: Tag,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New tag',
  //       path: '/admin/new-tag',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit tag',
  //       path: '/admin/edit-tag/:tagId',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  {
    label: 'Editor sadržaja',
    children: [
      {
        label: 'Naslovna strana',
        path: '/admin/Pages',
        component: Pages,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Reklamna strana',
        path: '/admin/LandingPages',
        component: LandingPage,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit Landing Page',
        path: '/admin/new-landing-page',
        component: EditLandingPage,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Landing Page',
        path: '/admin/edit-landing-page/:id',
        component: EditLandingPage,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New Front Page',
        path: '/admin/new-page',
        component: EditFrontPage,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit page',
        path: '/admin/edit-page/:id',
        component: EditFrontPage,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Uslovi korišćenja i ostalo',
        path: '/admin/info-text',
        component: InfoForCustomer,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Uslovi korišćenja i ostalo',
        path: '/admin/new-info-text',
        component: EditInfoForCustomer,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Uslovi korišćenja i ostalo',
        path: '/admin/new-info-text/:id',
        component: EditInfoForCustomer,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Baneri',
        path: '/admin/banners',
        component: Banner,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi baner',
        path: '/admin/new-banner',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edituj baner',
        path: '/admin/edit-banner/:bannerId',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Logo brendova',
        path: '/admin/brands-logos/',
        component: BrandsLogo,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj novi logo',
        path: '/admin/new-logo/',
        component: NewLogo,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edituj logo',
        path: '/admin/new-logo/:id',
        component: NewLogo,
        allowed: ['admin'],
        showInMenu: false,
      },

      {
        label: 'Blogovi',
        path: '/admin/blogs',
        component: Blog,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi blog',
        path: '/admin/new-blog',
        component: EditBlog,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni blog',
        path: '/admin/edit-blog/:id',
        component: EditBlog,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Stranice',
  //   children: [
  //     {
  //       label: 'Sve stranice',
  //       path: '/admin/pages',
  //       allowed: ['admin'],
  //       component: Landing,
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Nova stranica',
  //       path: '/admin/new-page',
  //       allowed: ['admin'],
  //       component: EditLanding,
  //       showInMenu: true,
  //     },
  //   ],
  // },
  {
    label: 'Narudžbe',
    children: [
      {
        label: 'Sve narudžbe',
        path: '/admin/orders',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni narudžbu',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Računi',
  //   children: [
  //     {
  //       label: 'Svi računi',
  //       path: '/admin/invoices',
  //       component: Invoices,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Povrat narudžbe',
  //   children: [
  //     {
  //       label: 'Svi povrati',
  //       path: '/admin/refunds',
  //       component: Refunds,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Izmeni povrat',
  //       path: '/admin/edit-refunds/:refundId',
  //       component: EditRefund,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Deklaracije',
  //   children: [
  //     {
  //       label: 'Sve deklaracije',
  //       path: '/admin/declarations',
  //       component: Declaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Nove deklaracije',
  //       path: '/admin/new-declaration',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Izmeni deklaraciju',
  //       path: '/admin/edit-declaration/:declarationId',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  {
    label: 'Newsletter',
    children: [
      {
        label: 'Svi newsletteri',
        path: '/admin/newsletter',
        component: Newsletter,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Uvoz podataka',
    children: [
      {
        label: 'Uvezi podatke',
        path: '/admin/ImportData',
        component: ImportData,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;

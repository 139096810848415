import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/BlogTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt', 'featureImage', 'blogText', 'blogShortText'];

const Blog = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/blogs`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/blog/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Podatak je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/blogs');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data && data.data.length > 0) {
    const keys = Object.keys(data.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data && data.data.length > 0) {
    tableData = data.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='table data-table special-data-table'>
      <div className='actions-block'>
        <Link to='/admin/new-blog'>
          <Button type='primary'>Novi blog</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data && data.data.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Podaci'
            editPath='/admin/edit-blog/'
          />
        )}
        {!data.isLoading && data.data && data.data && data.data.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;

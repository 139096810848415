import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['createdAt', 'updatedAt', 'email', 'firstName', 'lastName'];

const columns = [
  {
    key: '1',
    title: 'Naručeno',
    dataIndex: 'createdAt',
  },
  {
    key: '2',
    title: 'Modifikovano',
    dataIndex: 'updatedAt',
  },
  {
    key: '3',
    title: 'Email',
    dataIndex: 'email',
  },
  {
    key: '4',
    title: 'Ime i prezime',
    dataIndex: 'fullName',
  },
  {
    key: '5',
    title: 'Iznos narudžbe',
    dataIndex: 'totalAmount',
  },
];

// FORMATER CENA - NA SRPSKU VALUTU
const formatPrice = (number) => {
  const price = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    roundingIncrement: 5,
  }).format(number);
  return price.replace('€', 'RSD');
};

const Orders = () => {
  const currentuser = useContext(UserContext);
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [updateData, setUpdateData] = useState([]);
  const [createUpdateNew, setCreateUpdateNew] = useState([]);

  useEffect(() => {
    fetchOrders(`${SERVER_URL}/orders`, []);
  }, [fetchOrders]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Proizvod je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/orders');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (orders && orders.data && orders.data) {
      if (typeof orders.data.items !== 'undefined') {
        const newData = orders.data.items.map((item) => {
          const { firstName, lastName } = item.deliveryDetails ? item.deliveryDetails : item.guestUserData;
          const fullName = firstName + ' ' + lastName;
          return {
            ...item,
            fullName: fullName,
            // firstName: firstName,
            // lastName: lastName,
          };
        });

        setUpdateData(newData);
      }
      // let obj = {};
      // const priceFromatChanger = [];
      // orders.data.map((item) => {
      //   obj.totalAmount = formatPrice(item.totalAmount);
      //   priceFromatChanger.push(obj);
      // });
      // if (orders && orders.data && orders.data) {
      //   if (orders.data.hasOwnProperty('items')) {
      //     const newData = orders.data.items.map((item) => {
      //       return {
      //         ...item,
      //         totalAmount: formatPrice(item.totalAmount),
      //       };
      //     });

      //     setCreateUpdateNew(newData);
      //   }
      // }
    }
  }, [orders]);

  useEffect(() => {
    if (updateData && updateData.length > 0) {
      const newData = updateData.map((item) => {
        return {
          ...item,
          totalAmount: formatPrice(item.totalAmount),
        };
      });

      setCreateUpdateNew(newData);
    }
  }, [updateData]);

  let columnKeys;
  if (updateData && updateData.length > 0) {
    const keys = Object.keys(updateData[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'email');
  }
  let tableData = updateData;
  if (updateData && updateData.length > 0) {
    tableData = updateData.map((item) => {
      if (item.paymentMethod === 'paymentCash') item.paymentMethod = 'Plaćanje pouzećem - kurirska služba';

      if (item.paymentMethod === 'paymentStore') item.paymentMethod = 'Preuzimanje u radnji';

      if (item.paymentMethod === 'paymentBank') item.paymentMethod = 'Plaćanje na račun kompanije';

      if (item.user) {
        item.email = item.user.email;
        item.firstName = updateData.firstName;
        item.lastName = updateData.lastName;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      } else if (item.guestUserData) {
        item.email = item.guestUserData.email;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      } else if (item.deliveryDetails) {
        item.email = item.deliveryDetails.email;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      } else {
        return item;
      }
    });
  }

  return (
    <div className='table-orders-okvir'>
      <h1>Narudžbe</h1>
      <div className='actions-block'></div>
      <div style={{ textAlign: 'center' }}>
        {orders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!orders.isLoading && updateData && updateData.length > 0 && (
          <Table
            data={createUpdateNew}
            deleteHandler={deleteOrderHandler}
            columnKeys={TABLE_COLUMN_KEYS}
            columns={columns}
            title='Narudžbe'
            editPath='/admin/edit-order/'
            scroll={{ y: '88vh' }} // 74vh
          />
        )}
        {!orders.isLoading && updateData && updateData.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;

import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/BannersTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'featuredProduct',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'bannerType',
  'urlImage1',
  'urlImage2',
  'urlImage3',
  'urlImage4',
  'urlImage5',
  'urlImage6',
  'urlImage7',
];

const Banner = () => {
  const currentuser = useContext(UserContext);
  const [banners, fetchBanners] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  const desktopHeroBanner = '(1512x420)';
  const desktopTwoBanners = '(746x400)';
  const desktopOneBanner = '(1512x200)';
  const mobileHeroBanner = '(340x420)';
  const mobileRestBanners = '(340x300)';

  useEffect(() => {
    fetchBanners(`${SERVER_URL}/banners`, []);
  }, [fetchBanners]);

  const deleteBannerHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/banners/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/banners');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (banners.data && banners.data.items && banners.data.items.length > 0) {
    const keys = Object.keys(banners.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  const columnKeys2 = ['title', 'createdAt', 'updatedAt', 'status', 'mobileVersion'];

  let tableBanner = [];
  if (banners.data && banners.data.items && banners.data.items.length > 0) {
    tableBanner = banners.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      if (item.mobileVersion && item.mobileVersion === true) {
        if(item.title ===  'Hero slider') {
          item.title = 'Hero slider ' + mobileHeroBanner;
        } else if (item.title === 'Jedan baner') {
          item.title = "Jedan baner" + mobileRestBanners;
        } else if (item.title === 'Dva banera') {
          item.title = "Dva banera" + mobileRestBanners;
        }
        item.mobileVersion = <CheckOutlined />;
      }
      else if (!item.mobileVersion || item.mobileVersion === false) {
        if(item.title ===  'Hero slider') {
          item.title = 'Hero slider ' + desktopHeroBanner;
        } else {if(item.title === 'Jedan baner') {
          item.title = 'Jedan baner ' + desktopOneBanner;
        } else if(item.title === 'Dva banera') {
          item.title = 'Dva banera ' + desktopTwoBanners;
        }}
        item.mobileVersion = <CloseOutlined />;
      }
      return item;
    });
  }

  return (
    <div className='table data-table'>
      {currentuser.data.role.includes('admin') ? (
        <div className='actions-block'>
          <Link to='/admin/new-banner'>
            <Button type='primary'>Novi baner</Button>
          </Link>
        </div>
      ) : (
        ''
      )}

      <div style={{ textAlign: 'center' }}>
        {banners.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!banners.isLoading && banners.data && banners.data.items && banners.data.items.length > 0 && (
          <div>
            <Table
              banners={tableBanner}
              deleteHandler={deleteBannerHandler}
              columnKeys={columnKeys2}
              title='Banners'
              editPath='/admin/edit-banner/'
            />
            <br />
            <div>
              <p>U koloni <b>Tip</b> unutar zagrada naznačena je preporučena dimenzija slika.</p>
            </div>
          </div>

        )}
        {!banners.isLoading && banners.data && banners.data.items && banners.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema banera</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;

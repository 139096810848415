import React from 'react';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';

const ValueTable = ({ values, getColumnSearchProps, editValueHandler, deleteValueHandler, attrId }) => {
  const valueColumns = [
    {
      title: 'Vrednost atributa',
      width: '650px',
      dataIndex: 'value',
      ...getColumnSearchProps('value'),
    },
    {
      title: 'Akcija',
      width: '100px',
      render: (text, record, index) => (
        <div className='table-actions'>
          <div
            className='lock'
            style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
            title='Izmeni vrednost'
            onClick={() => editValueHandler(attrId, record?._id)}
          >
            <EditOutlined className='icon-unlock' />
            <EditFilled className='icon-lock' />
          </div>

          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <Popconfirm
              style={{ margin: '2px', padding: '4px' }}
              placement='left'
              title={`Ovo će obrisati vrednost ${record?.value}`}
              onConfirm={() => deleteValueHandler(attrId, record?._id)}
              okText='Prihvati'
              cancelText='Odustani'
            >
              <DeleteOutlined className='icon-unlock' />
              <DeleteFilled className='icon-lock' />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  // sorting attribute values in ascending order
  const sortedValues = values.sort((a, b) => {
    const valueA = a.value;
    const valueB = b.value;

    if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    } else if (!isNaN(valueA)) {
      return -1;
    } else if (!isNaN(valueB)) {
      return 1;
    } else {
      return valueA.localeCompare(valueB);
    }
  });

  // const sortedValues = values.sort((a, b) => {
  //   const valueA = parseInt(a.value);
  //   const valueB = parseInt(b.value);
  //   return valueA - valueB;
  // });

  return (
    <Table
      className='table-striped-rows'
      pagination={false}
      dataSource={sortedValues}
      columns={valueColumns}
      rowKey='_id'
    />
  );
};

export default ValueTable;

import React from 'react';
import { Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

const CategoryTree = ({ treeData, setTreeData, editHandler, deleteHandler }) => {

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  return (
    <div>
      {treeData.length > 0 && (
        <div style={{ height: '700px' }}>
          <SortableTree
            canDrag={false}
            treeData={treeData ? treeData : []}
            onChange={(data) => setTreeData(data)}
            onMoveNode={(data) => console.log('OnMove', data)}
            generateNodeProps={(rowInfo) => ({
              title: [<span>{rowInfo.node.naziv}</span>],
              buttons: [
                <button
                  type='button'
                  className='custom-button'
                  key={rowInfo.node.id + 'edit'}
                  title='Izmeni'
                  onClick={() => editHandler(rowInfo.node.ID, false, rowInfo.parentNode)}
                  // onClick={() => editHandler(rowInfo.node.ID, rowInfo.parentNode)}
                >
                  <EditOutlined />
                </button>,
                <Popconfirm
                  className='custom-button'
                  placement='left'
                  title={`Ovo će obrisati kategoriju ${rowInfo.node.title}`}
                  onConfirm={() => {
                    deleteHandler(rowInfo.node._id);
                  }}
                  okText='U redu'
                  cancelText='Odustani'
                >
                  <DeleteOutlined />
                </Popconfirm>,
                <div key={rowInfo.node._id + '_image'}>
                  {rowInfo.node.image && rowInfo.node.image.url ? (
                    <img
                      style={{ borderRadius: '7px', width: 50, height: 50 }}
                      src={getUrl(rowInfo.node.image.url)}
                      alt='category'
                    />
                  ) : null}
                </div>,
              ],
            })}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryTree;

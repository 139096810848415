import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { notification, Drawer /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUserModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  // const history = useHistory();
  const userId = props.userId;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`, []);
    }
  }, [userId, fetchUser]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${userId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Podatak je izmenjen.',
        placement: 'bottomRight',
      });
      // history.push('/admin');
      // history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      // let isError = false;
      // if (data.firstName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravno ime!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.lastName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravno prezime!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.password.includes(' ')) {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravna lozinka!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.city.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravan grad!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.country.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravna zemlja!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.companyName && data.companyName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravan naziv kompanije!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.taxId && data.taxId.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Neispravan PIB!',
      //     placement: 'bottomRight',
      //   });
      // }
      // console.log(isError);
      // if (isError === false) {
      // await Axios.post(`${SERVER_URL}/register-dashboard`, { ...data });
      // notification.success({
      //   message: 'Korisnik kreiran.',
      //   placement: 'bottomRight',
      // });
      // setResult(true);
      // }

      let legalType = '';

      if (data.legalType === 'Company') {
        legalType += 'COMPANY';
      }

      if (data.legalType === 'Private') {
        legalType += 'PRIVATE';
      }

      const payload = {
        ...data,
        legalType: legalType,
      };

      await Axios.post(`${SERVER_URL}/register`, { ...payload });
      notification.success({
        message: 'Korisnik kreiran.',
        placement: 'bottomRight',
      });
      setResult(true);
      window.location.reload();
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem korisnika. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <>
      {/* <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} /> */}
      {/* <div className={showModal ? 'modal show' : 'modal'}> */}
      {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

      <div style={{ textAlign: 'center', height: '100%' }}>
        {userId && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {userId && !user.isLoading && !user.isError && user.data && user.data.email && (
          <Drawer title={'Izmeni podatke'} placement='right' onClose={onClose} visible={props.showModal} width={420}>
            <UserForm
              data={user.data}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          </Drawer>
        )}
        {userId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Nešto je loše unešeno</h2>}
        {!userId && user && !user.data && (
          <Drawer title={'Dodaj korisnika'} placement='right' onClose={onClose} visible={props.showModal} width={420}>
            <UserForm
              data={null}
              createHandler={createUser}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Drawer>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default EditUserModal;

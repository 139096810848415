import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import { Tabs, Divider, Input, Checkbox, Select, /* DatePicker, */ Button, Form, Row, Col, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import GalleryModal from './GalleryModal';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 30;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  status: 'ACTIVE',
  gallery: undefined,
};

const LogosForm = ({ isNew, logos, language, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });

  if (logos) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete logos[key]);

  let initialValues = { ...formInit, ...logos };
  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(
    initialValues?.gallery?.length > 0 ? initialValues?.gallery : [],
  );

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = logos._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !logos)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <div className='panel panel-primary' style={{ width: '80%' }}>
              <div className='panel-heading'>
                <h4 className='panel-title'>Logoi brendova</h4>
              </div>

              <Form.Item name='gallery' valuePropName='image'>
                <div className='panel-body'>
                  {currentGallery?.length > 0 && (
                    <DNDGallery
                      deleteGalleryImageHandler={deleteGalleryImageHandler}
                      editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                      form={form}
                      setGallery={setCurrentGallery}
                    />
                  )}

                  {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                </div>
              </Form.Item>
            </div>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Ažuriraj'} logo
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/Logos/'
            imageType='data'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default LogosForm;

import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { CategoryForm, CategoryTree } from '../../components/forms';

const Categories = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [categories, fetchCategories] = useAxios('', [], currentuser?.data.token, 'get');
  const [edit, setEdit] = useState({ visible: false, category: {}, parent: {} });
  const [data, setData] = useState({ visible: false, category: {}, parent: {} });
  const [treeData, setTreeData] = useState([]);
  const [idAndLevel, setIdAndLevel] = useState({});

  const { categoryId } = props.match.params;

  const [flattenedCategories, setFlattenedCategories] = useState([]);

  const allSubCategories = categories.data;
  const result = [];
  const result2 = [];
  allSubCategories.map((obj) => {
    if (obj.children) {
      const el = { ...obj, ...{} };
      delete el.children;
      result.push(el);
      Object.values(obj.children).map((v, i) => {
        result.push(v);
      });
      result.map((child) => {
        if (child.children) {
          const el2 = { ...child, ...{} };
          delete el2.children;
          result2.push(el2);
          Object.values(child.children).map((v2, i) => {
            result2.push(v2);
          });
        }
      });
    } else {
      result.push(obj);
    }
  });

  useEffect(() => {
    if (result.length > 0) {
      setFlattenedCategories([...result, ...result2]);
    }
  }, [result.length]);

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (flattenedCategories.length > 0) {
      const selectedCategory = flattenedCategories.find((c) => c.ID === categoryId);

      setEdit({
        visible: true,
        category: selectedCategory || {},
        parent: selectedCategory ? selectedCategory?.parent : {},
      });
    }
  }, [fetchCategories, categoryId, categories?.data]);

  useEffect(() => {
    let data = [];
    data = getTreeFromFlatData({
      flatData: categories?.data?.map((node) => ({ ...node, title: node.naziv })),
      getKey: (node) => node._id, // resolve a node's key
      getParentKey: (node) => node.nivo, // resolve a node's parent's key
      rootKey: 0, // The value of the parent key when there is no parent (i.e., at root level)
    });

    data =
      data.length > 0
        ? data.map((data) => ({
            ...data,
            expanded:
              data.children && data.children.length > 0 && data.children.some((child) => child._id === categoryId),
          }))
        : [];

    setTreeData(data);
  }, [categories, currentuser, categoryId]);

  // DELETE CATEGORY
  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/categories/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Category deleted',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  // EDIT CATEGORY
  const editHandler = (id, parentNode) => {
    if (parentNode) {
      setEdit({ visible: true, category: { parent: parentNode }, parentNode });
    } else {
      setEdit({ visible: true, category: flattenedCategories.find((c) => c.ID === id), parentNode });
    }
  };

  // console.log(edit);

  useEffect(() => {
    const paramsID =
      edit.category.nivo === 0
        ? { id: edit.category.ID, level: edit.category.nivo }
        : edit.category.nivo === 1
        ? { childId: edit.category.ID, level: edit.category.nivo }
        : edit.category.nivo === 2
        ? { grandChildId: edit.category.ID, level: edit.category.nivo }
        : {};

    setIdAndLevel(paramsID);

    handlePopulate();
  }, [edit.category]);

  const handlePopulate = async () => {
    if (edit && edit.category && edit.category.ID) {
      const category = await Axios.get(`${SERVER_URL}/category/ID/${edit.category.ID}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setData({ ...edit, category: { ...category.data } });
    }
  };

  // SUBMIT ADD/EDIT CATEGORY
  const onSubmit = async (data, isNew) => {
    data.image = data.image;
    if (data.image === '') delete data.image;
    if (edit.category.parent) data.parent = edit.category.parent;

    const method = isNew ? 'post' : 'put';
    const route = idAndLevel.id
      ? `${SERVER_URL}/category-update?id=${idAndLevel.id}&level=${idAndLevel.level}`
      : idAndLevel.childId
      ? `${SERVER_URL}/category-update?childId=${idAndLevel.childId}&level=${idAndLevel.level}`
      : `${SERVER_URL}/category-update?grandChildId=${idAndLevel.grandChildId}&level=${idAndLevel.level}&childId=${edit.category.IDnadredjene}`;

    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Category ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Button type='primary' onClick={() => setEdit({ visible: true, category: {}, parentNode: {} })}>
          Dodaj kategoriju
        </Button>
      </div>

      <Row type='flex' gutter={8}>
        <Col xs={24} md={edit.visible ? 16 : 24}>
          <div className='panel panel-primary' style={{ textAlign: 'center', padding: '15px' }}>
            {categories.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

            {!categories.isLoading && categories.data && categories.data.length > 0 && (
              <CategoryTree
                treeData={treeData}
                setTreeData={setTreeData}
                token={currentuser.data.token}
                setEdit={setEdit}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
              />
            )}

            {!categories.isLoading && categories.data && categories.data.length === 0 && (
              <div className='no-data-box'>
                <h2>Nema kategorija</h2>
              </div>
            )}
          </div>
        </Col>

        {edit.visible && (
          <Col xs={24} md={8} className='add-categories'>
            <CategoryForm
              onSubmit={onSubmit}
              category={data.category}
              token={currentuser.data.token}
              setEdit={setEdit}
              SERVER_URL={SERVER_URL}
              fetchCategories={fetchCategories}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Categories;

import React, { useEffect, useState } from 'react';
import { Card, message, notification, Upload } from 'antd';
import { SERVER_URL } from '../../config';
import { InboxOutlined } from '@ant-design/icons';
import Axios from 'axios';

const { Dragger } = Upload;

const GalleryForm = ({ token, selectHandler, imageSavePath, imageType, imageHeight }) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const [images, setImages] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  useEffect(() => {
    try {
      const fetchImages = async () => {
        const imagesRes = await Axios.get(`${SERVER_URL}/images`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
        setImages(imagesRes.data.items);
      };
      fetchImages();
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='Dodaj novi sadržaj' bordered={false} style={{ width: '100%' }}>
          <Dragger
            multiple
            action={isDuplicate ? '' : `${SERVER_URL}/upload-image`}
            accept='.jpg,.jpeg,.png,.bmp,.svg'
            name='image'
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              // resize: true,
              // resizemob: false,
              height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={(file) => {
              if (images.find((image) => image.originalname === file.name)) {
                notification.error({
                  message: 'Slika sa ovim imenom već postoji.',
                  placement: 'bottomRight',
                });
                setIsDuplicate(true);
                return;
              }
              const isAllowedFormat = ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);
              if (!isAllowedFormat) {
                notification.error({
                  message: 'Možete postaviti samo JPG ili PNG slike!',
                  placement: 'bottomRight',
                });
              }
              const isAllowedSize = file.size / 1024 / 1024 < 50;
              if (!isAllowedSize) {
                notification.error({
                  message: 'Slika mora biti manja od 50MB!',
                  placement: 'bottomRight',
                });
              }
              return isAllowedFormat && isAllowedSize;
            }}
            onChange={async ({ file }) => {
              if (images.find((image) => image.originalname === file.name)) {
                message.error(`Postavljanje slike je neuspešno!`);
              } else {
                console.log('else file');
                setIsDuplicate(false);
                if (file.status === 'done') {
                  if (file.response && file.response.file) {
                    message.success(`${file.name} slika je uspešno postavljena!`);
                    let uploaded = sessionStorage.getItem('uploaded');
                    if (uploaded) {
                      sessionStorage.setItem('uploaded', `${uploaded},${file.response.image._id}`);
                    } else {
                      sessionStorage.setItem('uploaded', file.response.image._id);
                    }
                    // if (selectHandler) {
                    //   selectHandler(file.response.image._id);
                    // }
                  }
                } else if (file.status === 'error') {
                  message.error(`${file?.response?.file?.name} slika nije postavljena.`);
                }
              }
            }}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Klikni ili prevuci sliku do ove zone kako bi je postavili</p>
            <p className='ant-upload-hint'>
              Podržava pojedinačno ili grupno postavljanje slike. Strogo je zabranjeno postavljati kompanijske podatke
              ili druge ne primerene fajlove!
            </p>
          </Dragger>
        </Card>
      </div>
    </div>
  );
};

export default GalleryForm;

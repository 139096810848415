import React from 'react';
import { Divider, Input, Select, Button, Form, Row, Col } from 'antd';

const formInit = {
  _id: null,
  name: undefined,
  label: undefined,
  value: undefined,
};

const FrontPageForm = ({ isNew, data, categories, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();

  // if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  // Select ATTRIBUTES
  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item label='Naziv sekcije' name={'name'}>
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label='Naslov'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite naslov sekcije!',
                    },
                  ]}
                  name={'label'}
                >
                  <Input />
                </Form.Item>

                <Divider type='horizontal' />
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} naziv sekcije
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FrontPageForm;

import React, { useContext, useEffect, useState } from 'react';
// import slugify from 'slugify';
import JoditEditor from 'jodit-react';
import { Divider, Input, Select, /* DatePicker, */ Button, Form, Row, Col } from 'antd';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';

const { Option } = Select;

const configJodit = {
  style: {
    fontFamily: 'Poppins',
  },
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
    'image',
  ],
};

const options = [
  {
    label: 'Politika privatnosti',
    path: 'politika-privatnosti',
  },
  {
    label: 'Uslovi koriscenja',
    path: 'uslovi-korisenja',
  },
  {
    label: 'Nacin placanja',
    path: 'vrste-placanja',
  },
  {
    label: 'Nacin isporuke',
    path: 'nacin-dostave',
  },
  {
    label: 'Uputstvo za kupovinu',
    path: 'uputstvo-za-kupovinu',
  },
  {
    label: 'Sigurna kupovina',
    path: 'sigurna-kupovina',
  },
  {
    label: 'Najcesca pitanja',
    path: 'najcesca-pitanja',
  },
  {
    label: 'O nama',
    path: 'o-nama',
  },
];

const formInit = {
  _id: null,
  label: undefined,
  termsOfServiceDescription: undefined,
  path: undefined,
};

const InfoForCustomerForm = ({
  isNew,
  data,
  // brands,
  onSubmit,
  SERVER_URL,
  token,
}) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);

  let initialValues = { ...formInit, ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }

    const selectedOption = options.find((option) => option.label === values.label);
    values.path = selectedOption ? selectedOption.path : '';

    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  // className='employeeField'
                  label='Naziv stranice'
                  labelCol={{ span: 6 }}
                  name='label'
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {options.map((item, index) => (
                      <Option key={index} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Seo naziv' labelCol={{ span: 6 }} name='seoTitle'>
                  <Input></Input>
                </Form.Item>

                <Form.Item label='Seo opis' labelCol={{ span: 6 }} name='seoDescription'>
                  <Input></Input>
                </Form.Item>

                <Form.Item label='Ključne reči' labelCol={{ span: 6 }} name='keywords'>
                  <Input></Input>
                </Form.Item>

                <Form.Item label='Opis stranice' name={'termsOfServiceDescription'}>
                  <JoditEditor
                    name='termsOfServiceDescription'
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} tekst za sekciju
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InfoForCustomerForm;

import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { notification, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import DataForm from '../forms/ActionForm';

const ActionProductsModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [data, fetchData] = useAxios('', null, currentuser.data.token, 'get');
  const { showModal, setShowModal } = props;
  const dataId = props.modalId;

  useEffect(() => {
    if (dataId) {
      fetchData(`${SERVER_URL}/product/${dataId}`, []);
    }
  }, [dataId, fetchData]);

  const updateData = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/special-section/${dataId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Podatak je izmenjen.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
    setShowModal(!showModal);
    setTimeout(() => {
      // window.location.reload();
    }, 2000);
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center', height: '100%' }}>
        {data && data.data !== null ? (
          <Drawer title='Izmena akcija' placement='right' onClose={onClose} open={props.showModal}>
            <DataForm
              data={data.data}
              updateHandler={updateData}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          </Drawer>
        ) : null}
      </div>
    </>
  );
};

export default ActionProductsModal;

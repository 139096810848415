import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import LandingPageForm from '../../components/forms/LandingPageForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';

const EditLandingPage = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [page, fetchPage] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  const [brands, fetchBrands] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    fetchBrands(`${SERVER_URL}/get-brands`, [brands]);
  }, [fetchBrands]);

  useEffect(() => {
    if (id) fetchPage(`${SERVER_URL}/landing-page/${id}`, {});
  }, [fetchPage, id]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/landings` : `${SERVER_URL}/landing-page/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Reklamna strana ${isNew ? 'kreirana.' : 'ažurirana.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/LandingPages');
    } catch (error) {
      console.log('error::', error);
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const isDataFetched = !page.isLoading && page.data && currentuser.language;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/LandingPages'>
          <Button type='primary'>Sve reklamne stranice</Button>
        </Link>
      </div>
      <div>
        {/* <LandingPageForm isNew={false} data={page.data} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} /> */}
        {!id && (
          <LandingPageForm
            isNew={true}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {id && !page.isError && !page.isLoading && page.data && (
          <LandingPageForm
            isNew={false}
            data={page.data}
            brands={brands}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditLandingPage;
